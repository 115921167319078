@import url("https://fonts.googleapis.com/css2?family=Anuphan&display=swap");
body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  font-family: "Anuphan", sans-serif !important;
}

.el-table .el-table__cell {
  padding: 5px 0 !important;
}
.el-dialog {
  margin-top: 3vh !important;
}

@media (max-width: 800px) {
  .el-dialog {
    width: 90% !important;
  }
}

.cell {
  text-align: center;
}

.qrcode img {
  width: 100%;
}

.swal2-icon .swal2-icon-content {
  font-size: 1em !important;
}

.swal2-container {
  z-index: 9999999 !important;
}

.el-dialog__wrapper {
  overflow-y: scroll !important;
}

.el-popup-parent--hidden .main-panel {
  height: auto !important;
}

.el-select {
  width: 100%;
}

.sidebar .sidebar-wrapper .nav .nav-link p {
  font-size: 0.9rem;
}
.sidebar:before {
  opacity: 1;
  background: transparent;
}
.sidebar[data-color="black"]:after,
.bootstrap-navbar[data-color="black"]:after {
  background: transparent;
}
.sidebar {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #0f9b0f,
    #000000 100%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #0f9b0f,
    #000000 100%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.sidebar .logo-img {
  background: transparent;
}

.sidebar .logo-img img {
  max-width: 100%;
}

.card .card-header {
  padding: 15px 15px 10px 15px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.card .card-body {
  padding: 15px 15px 15px 15px;
}
.card label {
  /* font-size: 1rem; */
}

img.bank {
  width: 40px;
  border-radius: 5px;
}
.fa-light,
.fasl {
  font-weight: 300;
}
.fa-life-ring::before {
  content: "";
}
.fa-life-ring {
  height: 30px;
  font-size: 28px;
  margin-right: 15px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  float: left;
  background-size: 30px;
  background-image: url("/static/img/lottery.png");
}

/* .dialog-footer {
  display: flex;
  justify-content: space-between;
} */

.page-item.active .page-link {
  padding: 7px 0 !important;
}

/* .ps {
  position: relative;
}

.ps--active-y {
  position: unset;
} */

.btn-success {
  border: 1px solid #28a745;
  background-color: #28a745;
}

.sidebar .logo-img {
  width: 100%;
}

.sidebar .logo a.logo-mini {
  width: 50px;
  margin-left: 13px;
  margin-right: 13px;
}
/* .el-time-panel__content::after,
.el-time-panel__content::before {
  border: none !important;
} */
#tj-datetime-input {
  min-width: auto;
  width: 100%;
  height: auto;
  border: none;
}

.scroll-hider ul {
  height: 300px !important;
  width: 60px !important;
}

.sidebar .nav .nav-item .nav-link {
  margin: 0 !important;
  padding: 0.5rem 1rem !important;
}

.full-page-background {
  background-image: url(/static/img/full-screen-image-4.jpg);
}
